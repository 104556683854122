<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <router-link v-if="!$root.filterWithUrl" :to="sprintf('/module/question-solution/category/%d/book/%d/chapter', [categoryID, bookID])" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg"/>
            </span>
            {{ $t("pages.module.questionSolution.book.chapter.question.title") }}
        </router-link>
        <a class="btn btn-danger align-self-center" v-on:click="newMultiRecord()">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr009.svg"/>
            </span>
            {{ $t('pages.module.questionSolution.book.chapter.question.newMulti') }}
        </a>
    </div>
    <custom-table
        :title="sprintf($t('pages.module.questionSolution.book.chapter.question.titlePattern'), [chapter.title ?? ''])"
        :subTitle="$t('pages.module.questionSolution.book.chapter.question.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        selectableRows
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        filterComponentName="ModuleQuestionSolutionBookChapterQuestionFilter"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">
        <template v-slot:status="{ row: record }">
            <span :class="record.active ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.active ? $t('common.active') : $t('common.passive') }}</span>
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <a v-on:click="fetchRecord(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg"/>
                    </span>
                </a>
                <el-tooltip  :content="$t('btn.copy')" popper-class="max-w-300px" placement="bottom">
                    <a v-on:click="copyQuestionUrl(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                        <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/general/gen054.svg" />
                        </span>
                    </a>
                </el-tooltip>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_question" ref="questionModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="questionForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.questionSolution.book.chapter.question.cols.title') }}</label>
                                <el-form-item prop="title" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.title" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.questionSolution.book.chapter.question.cols.media.title') }}</label>
                                <el-form-item prop="file">
                                    <el-upload :file-list="form.file.list" :accept="$root.acceptFileSupported('video', '.')" class="upload-file" :on-change="handleChangeFile" :on-remove="handleChangeFile" :auto-upload="false" drag :multiple="false" :limit="1" :on-preview="previewFile" :class="form.file.list.length && 'uploadedFile'">
                                        <el-icon class="el-icon--upload">
                                             <span class="svg-icon">
                                                 <inline-svg src="/media/icons/duotune/files/fil022.svg"/>
                                             </span>
                                        </el-icon>
                                        <div class="el-upload__text">{{ $t('pages.module.questionSolution.book.chapter.question.cols.media.dragFile') }}</div>
                                    </el-upload>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('common.sort') }}</label>
                                <el-form-item prop="sort" :rules="$validation.getMessage(['required'])">
                                    <el-input-number v-model="form.data.sort" :min="1"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-10">
                                <label class="fs-6 fw-bold mb-2">{{ $t('common.status') }}</label>
                                <el-form-item prop="active">
                                    <el-radio-group v-model="form.data.active">
                                        <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                        <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
    <div class="modal fade" id="kt_modal_multi_question" ref="multiQuestionModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_multi_question_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmitMulti()" :model="form.data" ref="multiQuestionForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_multi_question_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.questionSolution.book.chapter.question.cols.media.title') }}</label>
                                <el-form-item prop="file">
                                    <el-upload :file-list="form.file.list" :show-file-list="false" :accept="$root.acceptFileSupported('video', '.')" class="upload-file" :on-change="handleChangeFile" :on-remove="handleChangeFile" :auto-upload="false" drag :multiple="true" :on-preview="previewFile">
                                        <el-icon class="el-icon--upload">
                                            <span class="svg-icon">
                                                <inline-svg src="/media/icons/duotune/files/fil022.svg"/>
                                            </span>
                                        </el-icon>
                                        <div class="el-upload__text">{{ $t('pages.module.questionSolution.book.chapter.question.cols.media.dragFile') }}</div>
                                    </el-upload>
                                    <draggable :list="form.file.uploadList" tag="ul" handle=".handle" class="el-upload-list el-upload-list--text">
                                        <template #item="{ element, index }">
                                            <li class="el-upload-list__item is-ready handle" tabindex="0" style="">
                                                <a class="el-upload-list__item-name">
                                                    <i class="el-icon el-icon--document">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                                                            <path fill="currentColor" d="M832 384H576V128H192v768h640V384zm-26.496-64L640 154.496V320h165.504zM160 64h480l256 256v608a32 32 0 0 1-32 32H160a32 32 0 0 1-32-32V96a32 32 0 0 1 32-32zm160 448h384v64H320v-64zm0-192h160v64H320v-64zm0 384h384v64H320v-64z"></path>
                                                        </svg>
                                                    </i>
                                                    {{ sprintf($t('pages.module.questionSolution.book.chapter.question.questionPatternWithName'), [(lastSortQuestion + index + 1), element.name]) }}
                                                </a>
                                                <label class="el-upload-list__item-status-label">
                                                    <i class="el-icon el-icon--upload-success el-icon--circle-check">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                                                            <path fill="currentColor" d="M512 896a384 384 0 1 0 0-768 384 384 0 0 0 0 768zm0 64a448 448 0 1 1 0-896 448 448 0 0 1 0 896z"></path>
                                                            <path fill="currentColor" d="M745.344 361.344a32 32 0 0 1 45.312 45.312l-288 288a32 32 0 0 1-45.312 0l-160-160a32 32 0 1 1 45.312-45.312L480 626.752l265.344-265.408z"></path>
                                                        </svg>
                                                    </i>
                                                </label>
                                                <i class="el-icon el-icon--close" @click="multiUploadDelete(element)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                                                        <path fill="currentColor" d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z"></path>
                                                    </svg>
                                                </i>
                                            </li>
                                        </template>
                                    </draggable>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";
import draggable from "vuedraggable";

export default {
    name: "index",
    components: {
        CustomTable,
        draggable
    },
    data() {
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    class: "",
                    key: "id"
                },
                {
                    name: this.$t("pages.module.questionSolution.book.chapter.question.cols.title"),
                    key: "title"
                },
                {
                    name: this.$t("common.sort"),
                    key: "sort"
                },
                {
                    name: this.$t("common.status"),
                    scopedSlots: {customRender: "status"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                file: {
                    list: [],
                    uploadList: []
                },
                data: {
                    options: {}
                }
            },
            chapter: {},
            selectedRowKeys: [],
            questionCopyUrlPattern: "%s/soru-cozumleri?category=%d&book=%d&chapter=%d&question=%d"
        }
    },
    computed: {
        chapterID() {
            return this.$route.params.chapter_id;
        },
        bookID() {
            return this.$route.params.book_id;
        },
        categoryID() {
            return this.$route.params.id;
        },
        table() {
            return this.$store.state.module.questionSolution.book.chapter.question.table;
        },
        lastSortQuestion(){
            return this.table.pagination.total;
        }
    },
    created() {
        if (!this.chapterID || !(this.chapterID > 0)) {
            this.$router.push({
                path: this.sprintf('/module/question-solution/category/%d/book/%d/chapter', [this.categoryID, this.bookID])
            });
        }
    },
    mounted() {
        this.setCurrentPageBreadcrumbs(this.$t("pages.module.questionSolution.book.chapter.question.title"), [this.$t("menu.moduleManagement")]);

        if (this.chapterID && this.chapterID > 0) {
            this.$store.dispatch('module/questionSolution/book/chapter/question/get', {
                page: {},
                filterData: Object.assign(this.$root.getFilterWithUrl({}), {
                    chapter_id: this.chapterID,
                    sort: "sort:asc"
                })
            });

            this.loadChapter();
        }
    },
    methods: {
        loadChapter() {
            this.axios.get(this.endpoints['module_question_solution_book_chapter'] + '/' + this.chapterID).then((response) => {
                let data = response.data.data;
                this.chapter = data;
            }).catch((error) => {
                if (error.response.data.message == 'record_not_found') {
                    this.$router.push({
                        path: this.sprintf('/module/question-solution/category/%d/book/%d/chapter', [this.categoryID, this.bookID])
                    });
                }
            });
        },
        newRecord() {
            this.form.updateStatus = false;

            this.form.file = {
                list: [],
                uploadList: []
            };

            this.form.data = {
                chapter_id: this.chapterID,
                sort: 1,
                active: true
            };

            this.form.title = this.$t("pages.module.questionSolution.book.chapter.question.new");

            this.showModal(this.$refs.questionModal);
        },
        fetchRecord(record) {
            this.form.updateStatus = true;
            this.form.title = this.$t("pages.module.questionSolution.book.chapter.question.edit");

            this.axios.get(this.endpoints['module_question_solution_book_chapter_question'] + '/' + record.id).then(response => {
                let data = response.data.data;

                this.form.data = data;

                this.form.file.list = [
                    {
                        name: data.upload.name,
                        url: data.upload.public_url
                    }
                ]

                this.showModal(this.$refs.questionModal);
            });
        },
        onSubmit() {
            this.$refs.questionForm.validate((valid) => {
                if (valid) {
                    if (!this.form.file.list.length > 0) {
                        this.$notify({
                            type: 'warning',
                            title: this.$t("messages.warning"),
                            message: this.$t("pages.module.questionSolution.book.chapter.question.warning.requiredMedia"),
                        })

                        return false;
                    }

                    this.form.loading = true;

                    if (this.form.file.uploadList.length > 0) {
                        this.submitFormWithUpload();
                    } else {
                        this.submitForm();
                    }
                } else {
                    return false;
                }
            });
        },
        submitFormWithUpload() {
            const formData = new FormData();

            this.form.file.uploadList.forEach(file => {
                formData.append("file[]", file.raw);
            });

            this.axios.post(this.endpoints['file'], formData).then(response => {
                this.onResponse(response.data, () => {
                    let data = response.data.data;
                    this.form.data.upload_id = data[0].id;
                    this.form.file.uploadList = [];
                    this.submitForm();
                }, undefined, false);
            }).catch(error => {
                this.onResponseFailure(error.response.data);
            }).finally(() => {
                this.form.loading = false;
            });
        },
        submitForm() {
            if (this.form.data.id) {
                this.axios.put(this.endpoints['module_question_solution_book_chapter_question'] + '/' + this.form.data.id, this.form.data).then(response => {
                    this.onResponse(response.data, () => {
                        this.refreshTable();
                        this.hideModal(this.$refs.questionModal);
                    });
                }).catch(error => {
                    this.onResponseFailure(error.response.data);
                }).finally(() => {
                    this.form.loading = false;
                });
            } else {
                this.axios.post(this.endpoints['module_question_solution_book_chapter_question'], this.form.data).then(response => {
                    this.onResponse(response.data, () => {
                        this.refreshTable();
                        this.hideModal(this.$refs.questionModal);
                    });
                }).catch(error => {
                    this.onResponseFailure(error.response.data);
                }).finally(() => {
                    this.form.loading = false;
                });
            }
        },
        deleteRecord(id) {
            this.$store.dispatch("module/questionSolution/book/chapter/question/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        refreshTable() {
            this.$store.dispatch("module/questionSolution/book/chapter/question/refresh");
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.newRecord();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("module/questionSolution/book/chapter/question/get", {
                page: pagination,
                filterData: Object.assign({
                    chapter_id: this.chapterID,
                    sort: "sort:asc",
                }, filterData)
            });
        },
        handleSelectedRow(record) {
            this.selectedRowKeys = record;
        },
        handleChangeFile(file, fileList) {
            this.form.file.list = fileList;
            this.form.file.uploadList = fileList;
        },
        previewFile(file) {
            if (file.url) {
                this.trustedWindowOpen(file.url, '_blank');
            }
        },
        newMultiRecord() {
            this.form.updateStatus = false;
            this.form.file = {list: [], uploadList: []};
            this.form.title = this.$t("pages.module.questionSolution.book.chapter.question.newMulti");

            this.showModal(this.$refs.multiQuestionModal);
        },
        multiUploadDelete(file) {
            this.form.file.uploadList.splice(file, 1);
        },
        onSubmitMulti() {
            this.$refs.multiQuestionForm.validate((valid) => {
                if (valid) {
                    if (!this.form.file.uploadList.length > 0) {
                        this.$notify({
                            type: 'warning',
                            title: this.$t("messages.warning"),
                            message: this.$t("pages.module.questionSolution.book.chapter.question.warning.requiredMedia"),
                        })

                        return false;
                    }

                    this.submitFormMultiUpload();
                } else {
                    return false;
                }
            });
        },
        submitFormMultiUpload() {
            this.form.loading = true;

            const formData = new FormData();
            this.form.file.uploadList.forEach(file => {
                formData.append("file[]", file.raw);
            });

            this.axios.post(this.endpoints['file'], formData).then(response => {
                this.onResponse(response.data, () => {
                    let data = response.data.data;

                    this.multiQuestionRequest(data).then((response) => {
                        this.onResponse(response, () => {
                            this.refreshTable();
                            this.hideModal(this.$refs.multiQuestionModal);
                        }, () => {
                            this.form.loading = false;
                        });
                    }).finally(() => {
                        this.form.loading = false;
                    })
                }, () => {}, false);
            }).catch(error => {
                this.onResponseFailure(error.response.data, () => {
                    this.form.loading = false;
                });
            });
        },
        async multiQuestionRequest(data){
            return new Promise((resolve, reject) => {
                let multiRequest = [];

                data.forEach((upload, uploadIndex) => {
                    multiRequest.push(this.axios.post(this.endpoints['module_question_solution_book_chapter_question'],
                        {
                            chapter_id: this.chapterID,
                            upload_id: upload.id,
                            title: this.sprintf(this.$t('pages.module.questionSolution.book.chapter.question.questionPattern'), [(this.lastSortQuestion + (uploadIndex + 1))]),
                            sort: (this.lastSortQuestion + (uploadIndex + 1)),
                            active: true
                        }
                    ));
                })

                this.axios.all(multiRequest).then(this.axios.spread((...responses) => {
                    let result = [];

                    for (let i = 0; i < responses.length; i++) {
                        let handleResponse = responses[i].data;

                        if (!handleResponse.status) {
                            break;
                        }

                        result.push(handleResponse.data);
                    }

                    if (result.length == responses.length) {
                        resolve({status: true, data: result});
                    } else {
                        reject('request_count_dont_match_with_response_count');
                    }
                })).catch((errors) => {
                    reject('occurred_any_error');
                })
            });
        },
        copyQuestionUrl(question) {
            let url = this.sprintf(this.questionCopyUrlPattern, [this.$root.appUrl, this.categoryID, this.bookID, this.chapterID, question.id])
            navigator.clipboard.writeText(url).then(() => {
                this.$swal.fire({
                    text: this.$t('messages.copyOk'),
                    icon: "success",
                    confirmButtonText: this.$t('btn.ok')
                })
            });
        },
    }
}
</script>

<style>
.uploadedFile .el-upload {
    display: none;
}
</style>